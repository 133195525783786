import React from 'react';
import { Helmet } from 'react-helmet';
import Footer, { getCookieConsentState } from '../components/Footer';
import Navbar from '../components/Navbar';
import './all.sass';
import useSiteMetadata from './SiteMetadata';
import { withPrefix } from 'gatsby';

const TemplateWrapper = ({ children }: any) => {
  const { title, description } = useSiteMetadata();

  const cookieConsentData = getCookieConsentState();

  return (
    <>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="referrer" content="no-referrer" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ed8a29"
        />
        <meta name="theme-color" content="#ed8a29" />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </>
  );
};

export default TemplateWrapper;
