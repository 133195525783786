import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Img from 'gatsby-image';
import { ImageType } from '../components/Content';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const NavItem = (props: NavItemType) => {
  const pageURI = typeof window !== `undefined` ? window.location.pathname + window.location.search : '';
  const liClassName = (props.path === pageURI) ? 'nav-item active' : 'nav-item';
  const aClassName = props.disabled ? 'nav-link disabled' : 'nav-link';

  return (
    <li className={liClassName}>
      <Link to={props.path} className={aClassName}>
        {props.name}
        {(props.path === pageURI) ? (<span className="sr-only"> (current)</span>) : ''}
      </Link>
    </li>
  );
};

type NavItemType = {
  path: string,
  disabled?: boolean,
  name: string,
};

export class NavigationBar extends React.Component<NavigationBarType> {
  render() {
    const data = this.props.data.markdownRemark.frontmatter;

    return (
      <Navbar bg="white" expand="sm" fixed="top">
        <Container fluid>
          <Navbar.Brand className="mr-3">
            <Link to="/">
              <PreviewCompatibleImage
                imageInfo={{
                  image: data.logo,
                  alt: 'LebensESSENz',
                }}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarContent" />

          <Navbar.Collapse id="navbarContent">
            <Nav className="mr-auto">
              {data.menuItems && data.menuItems.map((menuItem, idx) => (
                <NavItem key={`header-nav-${idx}`} path={`/${menuItem.link}`} name={menuItem.text} />
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };
};

type NavigationBarTypeRender = {
  markdownRemark: {
    frontmatter: {
      logo: ImageType,
      menuItems: [{
        link: string,
        text: string
      }],
    },
  },
};
type NavigationBarType = {
  data: NavigationBarTypeRender,
};

export default () => (
  <StaticQuery
    query={graphql`
      query NavigationBar {
        markdownRemark(frontmatter: { templateKey: { eq: "navbar" }}) {
          frontmatter {
            logo {
              childImageSharp {
                fluid(maxWidth: 100, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            },
            menuItems {
              text
              link
            }
          }
        }
      }
    `}
    render={(data: NavigationBarTypeRender) => <NavigationBar data={data} />}
  />
);
