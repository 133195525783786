import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ImageType } from '../components/Content';
import PreviewCompatibleImage from './PreviewCompatibleImage';

type ConsentStateType = {
  showConsent: boolean,
  showConsentClose: boolean,
  dataConsent: ConsentDataType,
};

type ConsentDataType = {
  cookieEssential: boolean,
  cookieStatistics: boolean,
}

const deleteAllCookies = () => {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";domain=" + document.domain + ";path=/");
  });
};

export function getCookieConsentState(): (ConsentDataType | null) {
  if (typeof localStorage === `undefined`) {
    return null;
  }
  const cookieConsent = localStorage.getItem('cookieConsent');
  if (!cookieConsent) {
    return null;
  }
  const data = JSON.parse(cookieConsent);
  if (!data.cookieStatistics) {
    deleteAllCookies();
  }
  return data;
};

export class FooterComponent extends React.Component<FooterType> {
  state: ConsentStateType;

  constructor(props: any) {
    super(props);
    this.state = this.loadConsentData();
  };

  handleChange = (e: any) => {
    const input = e.target;
    const key = input.id;
    const value = input.type === 'checkbox' ? input.checked : input.value;
    this.setState({
      dataConsent: {
        ...this.state.dataConsent,
        [key]: value,
      }
    });
  };

  toggleCookieConsent = (showConsent: boolean) => {
    this.setState({
      showConsent: showConsent,
    });
  };

  hideCookieConsent = (e?: any) => {
    if (!!e) {
      e.preventDefault();
    }
    this.toggleCookieConsent(false);
  };

  showCookieConsent = (e?: any) => {
    e.preventDefault();
    this.toggleCookieConsent(true);
  };

  saveConsentData = () => {
    if (typeof localStorage !== `undefined`) {
      localStorage.setItem('cookieConsent', JSON.stringify(this.state.dataConsent));
    }
    this.hideCookieConsent();
    if (typeof window !== `undefined`) {
      window.location.reload();
    }
  };

  loadConsentData = () => {
    const cookieConsent = getCookieConsentState();

    if (!cookieConsent) {
      return {
        showConsent: true,
        showConsentClose: false,
        dataConsent: {
          cookieEssential: true,
          cookieStatistics: false,
        },
      };
    }

    return {
      showConsent: false,
      showConsentClose: true,
      dataConsent: cookieConsent,
    };
  };

  acceptAll = (e?: any) => {
    this.setState({
      dataConsent: {
        cookieEssential: true,
        cookieStatistics: true,
      }
    }, () => {
      this.saveConsentData();
    });
  };
  acceptSelection = (e?: any) => {
    this.saveConsentData();
    if (!this.state.dataConsent.cookieStatistics) {
      deleteAllCookies();
    }
  };
  acceptEssential = (e?: any) => {
    this.setState({
      dataConsent: {
        cookieEssential: true,
        cookieStatistics: false,
      }
    }, () => {
      this.saveConsentData();
      deleteAllCookies();
    });
  };

  render() {
    const data = this.props.data.markdownRemark.frontmatter;

    return (
      <>
        <Container className="footer" fluid>
          <Row>
            <Col sm className="mb-3">
              <div className="footer-logo">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: data.logo,
                    alt: 'LebensESSENz'
                  }}
                />
              </div>
            </Col>
            <Col sm className="text-right text-left-xs">
              {data.logos && data.logos.map((logo, idx) => (
                <div key={`logo-${idx}`} className="footer-logo-extern mr-2 mt-2">
                  <a href={logo.link} title={logo.title}>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: logo.image,
                        alt: logo.title
                      }}
                    />
                  </a>
                </div>
              ))}
            </Col>
          </Row>
          {/*<Row className="mt-3">
            <Col>
              <p>{data.description}</p>
            </Col>
          </Row>*/}
          <Row>
            <Col>
              <Nav>
                <Nav.Item>
                  <Nav.Link disabled>Copyright &copy; {data.copyright}</Nav.Link>
                </Nav.Item>
                {data.menuItems && data.menuItems.map((menuItem, idx) => (
                  <Nav.Item key={`footer-nav-${idx}`}>
                    <Link to={`/${menuItem.link}`} className="nav-link">{menuItem.text}</Link>
                    {/*<Nav.Link href={menuItem.link}>{menuItem.text}</Nav.Link>*/}
                  </Nav.Item>
                ))}
                <Nav.Item>
                  <a href="#" className="nav-link" onClick={this.showCookieConsent}>Cookie-Einstellungen</a>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Container>
        {!!this.state.showConsent ? (
          <Container className="cookie-consent-container pt-3 pb-3 text-white">
            {!!this.state.showConsentClose ? (
              <button type="button" className="close text-shadow-none" onClick={this.hideCookieConsent}>
                <span aria-hidden="true" className="text-white">&#215;</span>
                <span className="sr-only">Close Cookie Consent</span>
              </button>
            ) : null}
            <p>
              LebensESSENz Ernährungs- & Gesundheitsberatung nutzt Cookies. Sie dienen dazu diese Webseite und Ihr Erlebnis zu verbessern.
              Nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in unserer <Link to="/datenschutz">Datenschutzerklärung</Link>.
            </p>
            <Form.Group controlId="cookieEssential" className="mb-0">
              <Form.Check type="checkbox" custom>
                <Form.Check.Input type="checkbox" defaultChecked={this.state.dataConsent.cookieEssential} disabled />
                <Form.Check.Label className="text-white">Essentiell</Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Form.Group controlId="cookieStatistics">
              <Form.Check type="checkbox" custom>
                <Form.Check.Input type="checkbox" defaultChecked={this.state.dataConsent.cookieStatistics} onChange={this.handleChange} />
                <Form.Check.Label>Statistik</Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Button variant="primary" block onClick={this.acceptAll}>Alle akzeptieren</Button>
            <Button variant="primary" block onClick={this.acceptSelection}>Auswahl speichern</Button>
            <Button variant="primary" block onClick={this.acceptEssential}>Nur Notwendige akzeptieren</Button>
            <Nav className="mt-3">
              {data.menuItems && data.menuItems.map((menuItem, idx) => (
                <Nav.Item key={`cookie-nav-${idx}`}>
                  <Link to={`/${menuItem.link}`} className="nav-link">{menuItem.text}</Link>
                </Nav.Item>
              ))}
            </Nav>
          </Container>
        ) : null}
      </>
    );
  };
};

type FooterTypeRender = {
  markdownRemark: {
    frontmatter: {
      logo: ImageType,
      logos: [{
        title: string,
        link: string,
        image: ImageType,
      }],
      description: string,
      menuItems: [{
        link: string,
        text: string
      }],
      copyright: string,
    },
  },
};
type FooterType = {
  data: FooterTypeRender,
};

export default () => (
  <StaticQuery
    query={graphql`
      query Footer {
        markdownRemark(frontmatter: { templateKey: { eq: "footer" }}) {
          frontmatter {
            logo {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            logos {
              image {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              link
              title
            }
            description
            menuItems {
              text,
              link
            },
            copyright
          }
        }
      }
    `}
    render={(data: FooterTypeRender) => <FooterComponent data={data} />}
  />
);
