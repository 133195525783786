import React from 'react';
import Img from 'gatsby-image';

const PreviewCompatibleImage = ({ imageInfo }: PreviewCompatibleImageType) => {
  const { alt = '', image, className = '' } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    return (
      <Img fluid={image.childImageSharp.fluid} alt={alt} className={className} />
    );
  }

  if (!!image && typeof image.url === 'string') {
    return (
      <img src={image.url} alt={alt} className={className} style={{ maxWidth: "100%" }} />
    );
  }

  return null;
};

type PreviewCompatibleImageType = {
  imageInfo: {
    alt?: string,
    image?: any | string,
    className?: string,
  }
}

export default PreviewCompatibleImage
